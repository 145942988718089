<template>
    <main>
        <div class="container">
         <createPost></createPost>
        </div>
    </main>    
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from '@/plugins/axios'
import noty from 'noty'
export default {
    components: { Form, Field, ErrorMessage},
    data () {
        return {
            postCreateStat:'',
            postAttachment:'',
            loader:false
        }
    },
    methods: {
         createPost: function (values) {
            this.postCreateStat ="";
            if( !( this.postAttachment)) return this.postCreateStat = 'Post cant be empty!'
            let self= this
            self.loader=true
            var formData = new FormData();
            for (let key in values) { formData.append(key, values[key]) }
            var postAttachment = document.querySelector('#postAttachment');
            formData.append("attachment", postAttachment.files[0]);
            formData.append('privacy','public')
            axios.post('/api/userpost', formData, {headers: {'Content-Type': 'multipart/form-data'},
                onUploadProgress: function (evnt) {  console.log('Updating package - ' + Math.round(evnt.loaded / evnt.total * 100) + '%');}
            }).then(resp => {
                self.loader=false
                document.querySelector('.createpost form').reset()
                document.querySelector('.createpost #postAttachment').value=""
                new noty({text: 'Posted' , type:"success", layout:"bottomRight" , timeout:1000}).show();

            }).catch(error=>{
                self.loader=false;
                console.log()
                new noty({text: error.response.data.message , type:"error", layout:"bottomRight" , timeout:1000}).show();
            })
         },
         onFileUpload(event) {
            this.postAttachment = event.target.files[0];
         },
    }
};
</script>